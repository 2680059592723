import React, { useState } from "react";
import { graphql } from "gatsby";
import { PageSummaryStyles } from "../styles";
import {
	Container,
	Footnotes,
	Definitions,
	References
} from "../components";
import { AudioTranscript } from "../components/AudioTranscript";

const Summary = ({ data }) => {
	const dataQuery = data.allMarkdownRemark.nodes[0],
		componentID = data.allMarkdownRemark.nodes[0].frontmatter.components_id,
		audioTracksWithProps = data.allMarkdownRemark.nodes[0].frontmatter.audioTracksWithProps[0],
		isiSnippet = data.allMarkdownRemark.nodes[0].frontmatter.isiSnippet,
		markdownID = `ed-summary-content`;
	const [trackWProps, setTrackWProps] = useState('default');
	const [transcriptState, setTranscriptState] = useState(false);

	return (
		<>
			<Container
				markdownID={markdownID}
				componentID={componentID}
				query={dataQuery}
				isiSnippet={isiSnippet}
				noSubNav={true}
				trackWProps={audioTracksWithProps[trackWProps]}
				setTrackWProps={setTrackWProps}
				transcriptState={transcriptState}
				setTranscriptState={setTranscriptState}
			>
				<PageSummaryStyles id={`main`} className={`wrapper`}>
					<section>
						<h1>
							<div className={`highlight`}>
								After 6 months of treatment failure for MAC lung disease,
							</div>
							Choose to add ARIKAYCE for your patients
						</h1>
						<div className={`summary-columns`}>
							<div className={`summary-column target`}>
								<span><b><u>Targets refractory MAC</u> lung disease right where it lives<sup>1-3</sup></b></span>
							</div>
							<div className={`summary-column patients`}>
								<span><b>Nearly <u>one-third of refractory patients</u> converted with the addition of ARIKAYCE<sup>1</sup></b></span>
								<span>In the CONVERT trial, the endpoints of the change from baseline in 6MWT distance and SGRQ did not demonstrate clinical benefit at Month 6<sup>1</sup></span>
							</div>
							<div className={`summary-column calendar`}>
								<span><b>Of those that responded, over half (n=36/65) of patients remained converted <u>15+ months</u> after initial conversion, even after treatment ended<sup>1</sup></b></span>
							</div>
							<div className={`summary-column sun`}>
								<span><b><u>Once-daily</u> inhalation<sup>1</sup></b></span>
							</div>
							<div className={`summary-column card`}>
								<span><b><u>Most insured patients</u> are covered*</b></span>
							</div>
						</div>
						<p className="purple"><b>Most Common Adverse Reactions:</b> The most common adverse reactions in the CONVERT trial at an incidence ≥5% for patients using ARIKAYCE plus background regimen compared to patients treated with background regimen alone were dysphonia (48% vs 2%), cough (40% vs 17%), bronchospasm (29% vs 11%), hemoptysis (18% vs 13%), musculoskeletal pain (18% vs 9%), upper airway irritation (18% vs 2%), ototoxicity (17% vs 10%), fatigue and asthenia (16% vs 10%), exacerbation of underlying pulmonary disease (15% vs 10%), diarrhea (13% vs 5%), nausea (12% vs 4%), headache (10% vs 5%), pneumonia (9% vs 9%), pyrexia (8% vs 5%), decreased weight (7% vs 1%), vomiting (7% vs 4%), rash (6% vs 1%), change in sputum (6% vs 1%), and chest discomfort (5% vs 3%).<sup>1</sup></p>
						<a target={`_blank`} href={`/arikares-enrollment-form-guide`}>Download the Enrollment Form</a>
					</section>
					<Footnotes
						isBottom={true}
						footnotes={dataQuery.frontmatter.footnotes}
					/>
					<Definitions
						markdownID={markdownID}
						definitions={dataQuery.frontmatter.definitions}
					/>
					<References
						markdownID={markdownID}
						references={dataQuery.frontmatter.references}
					/>
					<AudioTranscript id={`audio-transcript-main`} transcriptState={transcriptState} setTranscriptState={setTranscriptState} transcriptCopy={audioTracksWithProps[trackWProps].transcript} />
				</PageSummaryStyles>
			</Container>
		</>
	);
};

export const query = graphql`
  {
    allMarkdownRemark(
      filter: { frontmatter: { markdown_id: { eq: "ed-summary-content" } } }
    ) {
      nodes {
        frontmatter {
          slug
          description
          keywords
          markdown_id
          title
		  components_id
		  isiSnippet
		  audioTracksWithProps {
			  default {
				  trackID
				  isiContent
				  src
				  title
				  transcript
			  }
		  }
          definitions
          footnotes
          references
        }
        id
      }
    }
  }
`;

export default Summary;
